var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"rounded"},[(!_vm.loading)?_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","mb-4":""}},[_c('label',{staticClass:"right keyboard-input-toggler"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyboard),expression:"keyboard"}],staticClass:"d-none hidden",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.keyboard)?_vm._i(_vm.keyboard,null)>-1:(_vm.keyboard)},on:{"change":function($event){var $$a=_vm.keyboard,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.keyboard=$$a.concat([$$v]))}else{$$i>-1&&(_vm.keyboard=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.keyboard=$$c}}}}),_c('span',{staticClass:"ml-1 display-1"},[_c('font-awesome-icon',{attrs:{"icon":"keyboard","color":_vm.keyboard ? '#82b1ff' : '#c0c0c0'}})],1)])]),_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs6":"","text-xs-center":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"display-2 title-iconed ticket",attrs:{"xs12":"","mb-4":""}},[_c('span',{class:_vm.error.type == 'ticket'
                          ? 'red--text text--darken-2'
                          : ''},[_vm._v(_vm._s(_vm.$tc("ticket", 1)))])]),(!_vm.model.id)?_c('v-flex',{class:_vm.error.type == 'ticket'
                        ? 'display-1 red--text text--darken-2'
                        : 'display-1 blue--text text--lighten-1',attrs:{"xs12":""}},[_vm._v(_vm._s(_vm.$t("awaiting-scan")))]):_vm._e(),(_vm.model.id)?_c('v-flex',{staticClass:"display-1",attrs:{"xs12":"","green--text":"","text--darken-2":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","mb-4":""}},[(_vm.model.user)?_c('span',[_vm._v(" "+_vm._s(_vm.model.user.first_name)+" "+_vm._s(_vm.model.user.last_name)+" ")]):_vm._e()]),_c('v-flex',{attrs:{"xs12":""}},[_c('font-awesome-icon',{staticClass:"display-3",attrs:{"icon":"check-circle","color":"#5aab2e"}})],1)],1)],1):_vm._e()],1)],1),_c('v-flex',{attrs:{"xs6":"","text-xs-center":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"display-2 title-iconed chip",attrs:{"xs12":"","mb-4":""}},[_c('span',{class:_vm.error.type == 'token'
                          ? 'red--text text--darken-2'
                          : ''},[_vm._v(_vm._s(_vm.$tc("chip", 1)))])]),_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[(!_vm.token)?_c('v-flex',{class:_vm.error.type == 'token'
                            ? 'display-1 red--text text--darken-2'
                            : 'display-1 blue--text text--lighten-1',attrs:{"xs12":""}},[_vm._v(_vm._s(_vm.$t("awaiting-scan")))]):_vm._e(),(_vm.token)?_c('v-flex',{staticClass:"display-1",attrs:{"xs12":"","green--text":"","text--darken-2":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","mb-4":""}},[_vm._v(_vm._s(_vm.$t("paired")))]),_c('v-flex',{attrs:{"xs12":""}},[_c('font-awesome-icon',{staticClass:"display-3",attrs:{"icon":"check-circle","color":"#5aab2e"}})],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","mt-4":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-spacer'),(_vm.keyboard)?_c('v-flex',{staticClass:"display-1",attrs:{"xs6":"","blue--text":"","text--lighten-1":""}},[_c('v-text-field',{attrs:{"autofocus":"","box":"","hide-details":"","clearable":""},model:{value:(_vm.keyboardInput),callback:function ($$v) {_vm.keyboardInput=$$v},expression:"keyboardInput"}},[_c('template',{slot:"label"},[_c('div',[_vm._v(" Type in or scan "),_c('i',{staticClass:"blue--text text--lighten-1"},[_vm._v("chip")])])])],2)],1):_vm._e(),_c('v-spacer')],1)],1),(_vm.success)?_c('v-flex',{attrs:{"xs12":"","mt-5":"","text-xs-center":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","green--text":"","text--darken-2":""}},[_c('v-flex',{staticClass:"display-1",attrs:{"xs12":"","mb-4":""}},[_vm._v(" Welcome "+_vm._s(_vm.model.user.first_name)+" "+_vm._s(_vm.model.user.last_name)+"! ")]),(_vm.success)?_c('v-flex',{staticClass:"headline",attrs:{"xs12":""}},[_vm._v(" "+_vm._s(_vm.success)+" ")]):_vm._e()],1)],1):_vm._e(),(_vm.error.type)?_c('v-flex',{staticClass:"display-1",attrs:{"xs12":"","pt-5":"","red--text":"","text--darken-2":"","text-xs-center":""}},[_vm._v(_vm._s(_vm.error.message))]):_vm._e()],1)],1):_vm._e(),(_vm.loading)?_c('v-card-text',{staticClass:"text-xs-center"},[_c('v-progress-circular',{staticClass:"theme-accent",attrs:{"indeterminate":""}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }