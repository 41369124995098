<template>
  <div>
    <v-dialog v-model="dialog" width="800">
      <v-card class="rounded">
        <v-card-text v-if="!loading">
          <v-layout row wrap>
            <v-flex xs12 mb-4>
              <label class="right keyboard-input-toggler">
                <input
                  type="checkbox"
                  v-model="keyboard"
                  class="d-none hidden"
                />
                <span class="ml-1 display-1">
                  <font-awesome-icon
                    icon="keyboard"
                    :color="keyboard ? '#82b1ff' : '#c0c0c0'"
                  />
                </span>
              </label>
            </v-flex>
            <v-flex xs12>
              <v-layout row wrap>
                <v-flex xs6 text-xs-center>
                  <v-layout row wrap>
                    <v-flex xs12 mb-4 class="display-2 title-iconed ticket">
                      <span
                        :class="
                          error.type == 'ticket'
                            ? 'red--text text--darken-2'
                            : ''
                        "
                        >{{ $tc("ticket", 1) }}</span
                      >
                    </v-flex>
                    <v-flex
                      xs12
                      v-if="!model.id"
                      :class="
                        error.type == 'ticket'
                          ? 'display-1 red--text text--darken-2'
                          : 'display-1 blue--text text--lighten-1'
                      "
                      >{{ $t("awaiting-scan") }}</v-flex
                    >
                    <v-flex
                      xs12
                      v-if="model.id"
                      green--text
                      text--darken-2
                      class="display-1"
                    >
                      <v-layout row wrap>
                        <v-flex xs12 mb-4>
                          <span v-if="model.user">
                            {{ model.user.first_name }}
                            {{ model.user.last_name }}
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <font-awesome-icon
                            icon="check-circle"
                            color="#5aab2e"
                            class="display-3"
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs6 text-xs-center>
                  <v-layout row wrap>
                    <v-flex xs12 mb-4 class="display-2 title-iconed chip">
                      <span
                        :class="
                          error.type == 'token'
                            ? 'red--text text--darken-2'
                            : ''
                        "
                        >{{ $tc("chip", 1) }}</span
                      >
                    </v-flex>
                    <v-flex xs12>
                      <v-layout row wrap>
                        <v-flex
                          xs12
                          v-if="!token"
                          :class="
                            error.type == 'token'
                              ? 'display-1 red--text text--darken-2'
                              : 'display-1 blue--text text--lighten-1'
                          "
                          >{{ $t("awaiting-scan") }}</v-flex
                        >
                        <v-flex
                          xs12
                          v-if="token"
                          green--text
                          text--darken-2
                          class="display-1"
                        >
                          <v-layout row wrap>
                            <v-flex xs12 mb-4>{{ $t("paired") }}</v-flex>
                            <v-flex xs12>
                              <font-awesome-icon
                                icon="check-circle"
                                color="#5aab2e"
                                class="display-3"
                              />
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 mt-4>
              <v-layout row wrap>
                <v-spacer></v-spacer>
                <v-flex
                  xs6
                  v-if="keyboard"
                  blue--text
                  text--lighten-1
                  class="display-1"
                >
                  <v-text-field
                    v-model="keyboardInput"
                    autofocus
                    box
                    hide-details
                    clearable
                  >
                    <template slot="label">
                      <div>
                        Type in or scan
                        <i class="blue--text text--lighten-1">chip</i>
                      </div>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>
            </v-flex>
            <v-flex xs12 v-if="success" mt-5 text-xs-center>
              <v-layout row wrap green--text text--darken-2>
                <v-flex xs12 mb-4 class="display-1">
                  Welcome {{ model.user.first_name }}
                  {{ model.user.last_name }}!
                </v-flex>
                <v-flex xs12 class="headline" v-if="success">
                  {{ success }}
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              v-if="error.type"
              xs12
              pt-5
              red--text
              text--darken-2
              class="display-1"
              text-xs-center
              >{{ error.message }}</v-flex
            >
          </v-layout>
        </v-card-text>
        <v-card-text v-if="loading" class="text-xs-center">
          <v-progress-circular
            indeterminate
            class="theme-accent"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    groupPluginId: {
      type: [String, Number],
      required: true,
    },
    ticketTemplateId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    loading: false,
    dialog: false,
    scannerInput: [],
    keyboard: false,
    keyboardInput: null,
    model: {},
    ticket: null,
    token: null,
    error: {},
    success: "",
    timeout: null,
  }),
  computed: {
    inputValue() {
      return this.scannerInput.join("").replace(/\s/g, "");
    },
  },
  watch: {
    keyboard() {
      this.keyboardInput = null;
    },
    keyboardInput(val) {
      if (val) {
        const newVal = val.replace(/\s/g, "");
        this.validateInput(newVal);
      }
    },
    inputValue(val) {
      if (val) {
        this.validateInput(val);
      }
    },
    token(val) {
      if (val) {
        let userId;

        if (this.model && this.model.user && this.model.user.id) {
          userId = this.model.user.id;
        }

        this.createPhysicalToken(val, userId);
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", (event) => {
      const charList = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 ";
      const key = event.key.toUpperCase();
      if (charList.indexOf(key) === -1) return;
      this.scannerInput.push(key);
    });
  },
  methods: {
    closeDialog() {
      setTimeout(() => {
        this.dialog = false;
      }, 3000);
    },
    validateInput(val) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.scannerInput = [];
      }, 500);

      const tokenRegExp = new RegExp("^([A-F0-9]{2}){7}$");

      if (tokenRegExp.test(val)) {
        this.token = val;
        this.scannerInput = [];
        this.keyboardInput = null;
      }
    },
    async validateTicket(code) {
      this.scannerInput = [];
      this.keyboard = false;
      this.keyboardInput = null;
      this.model = {};
      this.ticket = null;
      this.token = null;
      this.error = {};
      this.success = "";
      this.timeout = null;

      let mask = this.ticketMask ? this.ticketMask : "[A-Za-z0-9]{5}";
      const ticketRegExp = new RegExp(`^T${this.ticketTemplateId}(${mask})$`);

      return new Promise((resolve) => {
        resolve(ticketRegExp.test(code));
      });
    },
    async checkTicket(code) {
      const valid = await this.validateTicket(code);

      if (!valid) return;

      this.loading = true;
      this.dialog = true;

      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPluginId,
          ticket_template_id: this.ticketTemplateId,
          code: code,
        },
      ];

      this.$api.groupTickets
        .check(...params)
        .then((response) => {
          if (response && response.data.status === "ok") {
            this.loading = false;
            this.ticket = code;

            if (response.data.data.valid) {
              this.model = response.data.data;
            } else {
              this.error = {
                type: "ticket",
                message: this.$t("ticket-already-used"),
              };
            }
          } else {
            this.loading = false;
            this.error = {
              type: "ticket",
              message: response.data.error.message,
            };
          }
        })
        .catch(() => {});
    },
    checkInTicket() {
      if (!this.ticket) return;

      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPluginId,
          ticket_template_id: this.ticketTemplateId,
          code: this.ticket,
        },
      ];

      this.$api.groupTickets
        .checkin(...params)
        .then((response) => {
          if (response && response.data.status === "ok") {
            if (response.data.data.valid) {
              this.error = {};
              this.success = this.$t("checked-in-and-chip-assigned");
              this.$emit("checked-in");
            } else {
              this.error = {
                type: "ticket",
                message: this.$t("ticket-already-used"),
              };
            }

            this.closeDialog();
          } else {
            this.error = {
              type: "ticket",
              message: response.data.error.message,
            };

            this.closeDialog();
          }
        })
        .catch(() => {});
    },
    createPhysicalToken(token, userId) {
      if (!token || !userId) return;

      const specs = [
        this.groupId,
        userId,
        "rfid",
        {
          user_id: userId,
          token: token,
          type: "rfid",
        },
      ];

      this.$api.groupUserPhysicalTokens
        .create(...specs)
        .then((response) => {
          if (response && response.data.status === "ok") {
            this.checkInTicket();
          } else {
            this.error = {
              type: "token",
              message: this.$t("chip-already-used"),
            };
            this.token = null;
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.keyboard-input-toggler {
  cursor: pointer;
}
.hidden {
  opacity: 0;
}
.title-iconed span {
  padding-right: 80px;
  position: relative;
}
.title-iconed span:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.title-iconed.ticket span:after {
  background-image: url("~@/assets/images/icon-ticket.png");
}
.title-iconed.chip span:after {
  background-image: url("~@/assets/images/icon-chip.png");
}
</style>
