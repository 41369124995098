<template>
  <div>
    <v-dialog v-model="dialog" width="600">
      <v-card class="rounded">
        <v-card-text v-if="!loading">
          <v-layout row wrap>
            <v-flex xs12 v-if="success" text-xs-center>
              <v-layout row wrap>
                <v-flex xs12 mb-4 class="display-2 title-iconed ticket">
                  <span :class="error ? 'red--text text--darken-2' : ''">
                    {{ $tc("ticket", 1) }}
                  </span>
                </v-flex>
                <v-flex
                  xs12
                  mb-4
                  v-if="model.id"
                  green--text
                  text--darken-2
                  class="display-1"
                >
                  <v-layout row wrap>
                    <v-flex xs12 mb-4>
                      <span v-if="model.user">
                        {{ model.user.first_name }}
                        {{ model.user.last_name }}
                      </span>
                    </v-flex>
                    <v-flex xs12>
                      <font-awesome-icon
                        icon="check-circle"
                        color="#5aab2e"
                        class="display-3"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 class="headline green--text text--darken-2">
                  {{ success }}
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              v-if="error"
              xs12
              red--text
              text--darken-2
              class="display-1"
              text-xs-center
              >{{ error }}</v-flex
            >
          </v-layout>
        </v-card-text>
        <v-card-text v-if="loading" class="text-xs-center">
          <v-progress-circular
            indeterminate
            class="theme-accent"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    groupPluginId: {
      type: [String, Number],
      required: true,
    },
    ticketTemplateId: {
      type: [String, Number],
      required: true,
    },
    ticketMask: {
      type: String,
    },
  },
  data: () => ({
    model: {},
    loading: false,
    dialog: false,
    error: "",
    success: "",
  }),
  methods: {
    closeDialog() {
      setTimeout(() => {
        this.dialog = false;
      }, 3000);
    },
    async validateTicket(code) {
      this.error = "";
      this.success = "";

      const mask = this.ticketMask ? this.ticketMask : "[A-Za-z0-9]{5}";
      const ticketRegExp = new RegExp(`^T${this.ticketTemplateId}(${mask})$`);

      return new Promise((resolve) => {
        resolve(ticketRegExp.test(code));
      });
    },
    async checkInTicket(code) {
      const valid = await this.validateTicket(code);

      if (!valid){
        // console.error('code does not match with template id', code);
        // return;
      }

      this.loading = true;
      this.dialog = true;

      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPluginId,
          ticket_template_id: this.ticketTemplateId,
          code: code,
        },
      ];

      this.$api.groupTickets
        .checkin(...params)
        .then((response) => {
          if (response && response.data.status === "ok") {
            this.loading = false;

            if (response.data.data.valid) {
              this.model = response.data.data;
              this.success = this.$t("you-are-checked-in");

              this.$emit("checked-in");
            } else {
              this.error = this.$t("ticket-already-used");
            }

            this.closeDialog();
          } else {
            this.loading = false;
            this.error = response.data.error.message;

            this.closeDialog();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.title-iconed span {
  padding-right: 80px;
  position: relative;
}
.title-iconed span:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.title-iconed.ticket span:after {
  background-image: url("~@/assets/images/icon-ticket.png");
}
</style>
