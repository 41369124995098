<template>
  <v-container class="general">
    <page-title :component="'GroupTickets'"></page-title>
    <v-layout v-if="!loading" row wrap>
      <v-flex v-if="ticket && ticket.user" xs12 mb-4>
        <v-layout row wrap>
          <v-flex md5 xs12>
            <v-card class="px-4 py-3 border-radius" height="100%">
              <v-layout row wrap>
                <v-flex class="xs12 mb-3 theme-primary">
                  <h3>
                    {{ ticket.user.first_name }} {{ ticket.user.last_name }}
                  </h3>
                </v-flex>
                <v-flex xs12
                  >{{ $tc("email", 1) }}: {{ ticket.user.email }}</v-flex
                >
                <v-flex xs12>
                  <span v-if="ticket.user.phone_code"
                    >{{ $tc("phone", 1) }}: +{{ ticket.user.phone_code }}</span
                  >
                  {{ ticket.user.phone }}
                </v-flex>
                <v-flex xs12
                  >{{ $tc("ticket-id", 1) }}: {{ ticket.code }}</v-flex
                >
                <v-flex
                  v-if="
                    ticket.user_info_text && ticket.user_info_text.length > 0
                  "
                  class="pt-5"
                  xs12
                >
                  <div v-html="unparseContent(ticket.user_info_text)" class="sw-content-typography"></div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex md7 xs12>
            <v-card class="px-4 py-3 border-radius" height="100%">
              <v-layout row wrap>
                <v-flex xs12 mb-3>
                  <h3>{{ $t("actions") }}</h3>
                </v-flex>
                <v-flex xs12 mb-3>
                  <v-btn
                    round
                    class="ml-0 sw-accent-bg sw-on-accent text-none"
                    v-if="!ticket.redeemed"
                    @click="checkInTicket"
                    >{{ $t("check-in") }}</v-btn
                  >
                  <v-btn
                    round
                    class="ml-0 sw-accent-bg sw-on-accent text-none"
                    v-if="ticket.redeemed"
                    @click="checkOutTicket"
                    >{{ $t("check-out") }}</v-btn
                  >
                  <v-btn
                    round
                    class="ml-0 sw-accent-bg sw-on-accent text-none"
                    @click="printTicket()"
                  >
                    <span class="mr-2">
                      <font-awesome-icon icon="print" />
                    </span>
                    {{ $t("print-pdf") }}
                  </v-btn>
                  <!-- <v-btn
                    v-if="usePhysicalToken && groupPluginId"
                    round
                    class="accent text-none"
                    @click="depositsDialog = true"
                    >{{ $t("wardrobe") }}</v-btn
                  >-->
                </v-flex>
                <v-flex
                  v-if="ticket.redeemed"
                  xs12
                  mb-3
                >
                  Checked in:
                  {{ transformDate(ticket.redeemed_at) }}
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex class="xs12 text-xs-center">
        <v-btn round class="text-none" @click="toTicketsList()">{{
          $t("cancel")
        }}</v-btn>
      </v-flex>
    </v-layout>
    <!-- <TicketModal
      :groupId="groupId"
      :groupPluginId="groupPluginId"
      :ticketTemplateId="ticketTemplateId"
      ref="ticketModalRef"
      @checked-in="showTicket"
    /> -->
    <TicketWithTokenModal
      v-if="usePhysicalToken"
      :groupId="groupId"
      :groupPluginId="groupPluginId"
      :ticketTemplateId="ticketTemplateId"
      ref="ticketModalRef"
      @checked-in="showTicket"
    />
    <TicketModal
      v-if="!usePhysicalToken"
      :groupId="groupId"
      :groupPluginId="groupPluginId"
      :ticketTemplateId="ticketTemplateId"
      ref="ticketModalRef"
      @checked-in="showTicket"
    />
    <!-- <v-dialog v-model="depositsDialog" width="800">
      <v-card v-if="usePhysicalToken && groupPluginId" class="pa-4 rounded">
        <Deposits
          v-if="ticket.user"
          :userData="ticket.user"
          :depositsPluginId="groupPluginId"
          @close-inventory="depositsDialog = false"
        />
      </v-card>
    </v-dialog>-->
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import TicketWithTokenModal from "@/components/Checkin/Modal/TicketWithTokenModal.vue";
import TicketModal from "@/components/Checkin/Modal/TicketModal.vue";
// import Deposits from "@/components/TicketPage/Deposits.vue";

export default {
  data: () => ({
    loading: false,
    ticket: {},
    plugin: {},
    // depositsDialog: false,
  }),
  computed: {
    ...mapGetters(["getLeftMenu"]),
    groupId() {
      return Number(this.$route.params.group_id);
    },
    groupPluginId() {
      return Number(this.$route.params.group_plugin_id);
    },
    ticketTemplateId() {
      return Number(this.$route.params.ticket_template_id);
    },
    ticketId() {
      return Number(this.$route.params.ticket_id);
    },
    usePhysicalToken() {
      return this.plugin.attributes &&
        this.plugin.attributes.ticketwizard_wardrobe_enabled
        ? true
        : false;
    },
  },
  components: {
    TicketWithTokenModal,
    TicketModal,
    // Deposits,
  },
  methods: {
    getGroupPlugin() {
      const params = [this.groupId, this.groupPluginId, { with_attributes: 1 }];
      return this.$api.groupPlugins.get(...params);
    },
    toTicketsList() {
      this.$router
        .push({
          name: "tickets",
          params: {
            group_id: this.groupId,
            group_plugin_id: this.groupPluginId,
            ticket_template_id: this.ticketTemplateId,
          },
        })
        .catch(() => {});
    },
    printTicket() {
      if (!this.ticket || !this.ticket.id) {
        return;
      }

      const params = [
        this.groupId,
        this.ticketId,
        {
          group_plugin_id: this.groupPluginId,
          ticket_template_id: this.ticketTemplateId,
        },
      ];

      this.$api.groupTickets
        .delete_pdf(...params)
        .then((response) => {
          window.open(
            `${process.env.VUE_APP_API_URL}groups/${this.groupId}/tickets/${this.ticket.security_token}/${this.ticket.id}`,
            "_blank",
          );
        })
        .catch(() => {});
    },
    async showTicket() {
      try {
        this.loading = true;

        const gpress = await this.getGroupPlugin();

        if (!gpress || !gpress.data.data) return;

        this.plugin = gpress.data.data;

        const gtparams = [
          this.groupId,
          this.ticketId,
          {
            group_plugin_id: this.groupPluginId,
            ticket_template_id: this.ticketTemplateId,
          },
        ];

        const gtress = await this.$api.groupTickets.get(...gtparams);

        this.ticket = gtress.data.data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    checkInTicket() {
      if (!this.$refs.ticketModalRef && !this.ticket.code) return;

      if (this.usePhysicalToken) {
        this.$refs.ticketModalRef.checkTicket(this.ticket.code);
      } else {
        this.$refs.ticketModalRef.checkInTicket(this.ticket.code);
      }
    },
    checkOutTicket() {
      if (!this.ticket || !this.ticket.id) {
        return;
      }

      const params = [
        this.groupId,
        this.ticket.id,
        {
          ticket_template_id: this.ticketTemplateId,
        },
      ];

      this.$api.groupTickets
        .checkout(...params)
        .then(() => {
          this.showTicket();
        })
        .catch(() => {});
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.showTicket();
      },
    },
  },
};
</script>
